.flexible-container{
    display: flex;
    flex-wrap: wrap;
    //justify-content: center;
    //justify-content: space-around;
    //justify-content: space-between;
    
    margin-left: 65px;
    //margin-top: 50px;
    min-height: 80vh;


    flex-direction: column;
    
    align-items: center;
    align-content: center;
    background-color: #FAFAFA;

    @media screen and (max-width: 500px){
        margin-left: 0px;
    }
}

.flexible-item{
    width: 90%;
    //height: 100px;
    // border: .1px solid rgb(201, 201, 201);
    //background-color: grey;
    //margin: 50px;

}

.row {
    display: flex;
  }
  
.column {
    float: left;
    //padding: 25px;
    //flex: 50%;

    //height: 100px;
    //background-color: #026BFB;
}

.left {
    //padding-top: 25px;
    //padding-left: 25px;
    width: 20%;
    //background-color: yellow;
}

.right {
    //padding-top: 25px;
    padding-left: 25px;
    width: 80%;
    //background-color: red;   
    float: right;
}

.newOrder {

    border-radius: 50px;
    background: #D9D9D9;
    white-space: nowrap;
    padding: 10px 22px;
    color: #010606;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    margin: 5px;
    float: right;
    max-width: 200px;
    margin-right: 30px;
    margin-left: 25px;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;


    &:hover {
        transition: all 0.2s ease-in-out;
        background: #026BFB;
        color: #010606;
        
    }
}

.newOrderBadge {
    position:absolute;
    top: -15px;
    right: -18px;
    color: #026BFB;
    padding: 2px;
}

.searchBar {
    //display: inline-block;
    //background-color: red;
    max-width: 300px;
    max-height: 40px;
    padding-top: 5px;
    
    float: right;

    @media screen and (max-width: 768px){
        //margin: 0px;
        display: none !important;
    }
}

.searchBar .MuiOutlinedInput-root {
    border-radius: 20px;
  }


.orderCategoryMenu {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style:  none;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
    //justify-content: space-around;
    //justify-items: center;
    //padding-left: 5px
    //margin-left: 10px;
    //background-color: red;
}

.orderCategoryItem {
    height: 50px;
    //background-color: yellow;
    //width: 100;
}

.orderCategoryButton {
    background: none;
	color: inherit;
	border: none;
	//padding: 0;
	//font: inherit;

    color: rgb(204, 204, 204);
    display: flex;
    align-items: center;
    text-decoration: none;
    //padding: 0 1rem;
    height: 100%;
    font-size: 20px;
    cursor: pointer;

    box-sizing: border-box;

    
    &.active{
        border-bottom: 3px solid #026BFB
    }

    &:hover{
        //color: #026BFB;
        //font-weight: bold;
        //transition: 0.2s ease-in-out;
        border-bottom: 3px solid #515151;
        color: black
        //text-decoration: overline;

    }
}

.flexible-item-1{
    min-height: 80px;
    margin-top: 50px;
}

.flexible-item-2{
    min-height: 50px;

}

.flexible-item-3{
    min-height: 300px;
    border: .1px solid rgb(201, 201, 201);

}

.orderTable {
    display: flex;
    margin-left: 115px;
    margin-top: 0px;
    margin-right: 50px;
    //font-size: 36px;
    //font-family: Arial, Helvetica, sans-serif;

    border-bottom: 1px solid #EEEEEE
}

.tableHead .MuiTableHead-root{
    font-weight: 550;
    font-size: 16px;
    //color: #e01010;
}

.emptyMessages{
    //display: flex;
    justify-content: center;
    align-items: center;
    //margin-top: 100px;
    text-align: center;
}

.firstOrder {
    border-radius: 50px;
    background: #D9D9D9;
    white-space: nowrap;
    padding: 10px 22px;
    color: #010606;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    margin: 5px;
    //float: right;
    max-width: 200px;
    margin-right: 30px;
    margin-left: 25px;
    margin-bottom: 100px;



    &:hover {
        transition: all 0.2s ease-in-out;
        background: #026BFB;
        color: #010606;
        
    }
}

.orderRow {
    background-color: #F7F7F7;
}

.orderCell{
    //background-color: yellow;
    margin-top: 0px;
    margin-bottom: 0px;
}

.rectangle{
    width: 200px;
    background-color: #D9D9D9;
    height: 5px;
    display: inline-block;
}


.circle{
        width: 13px;
        height: 13px;
        background: white;
        border-radius: 50%;
        float: right;
        vertical-align:middle;
        border: 1.5px solid #000000;

        margin-top: -3.5px;
        margin-right: 0px;
}

.statusView{
    //background-color: red;
}
.statusBarText{
    font-size: 14px;
    float: left;
    //margin-left: 10px; 
    margin-top: -3px;
}

.statusBarText2{
    float: right;

    font-size: 14px;
    //margin-left: 10px;
    //margin-right: 10px;
    margin-top: -3px;
}

.statusBarText3{
    float: left;

    font-size: 14px;
    margin-left: 10px;
    margin-bottom: -100px;
    //display: flex;
}