.documentContainer{
    //background-color: red;
    margin-left: 50px;
}

.documentLists{
 color: #026BFB;

}

.linkDocuments{
    color: #026BFB;
    
   }