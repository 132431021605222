
.orderProgress{
    flex: 2;
    //padding: 10px;
    //display:flex;
    //border-radius: 10px;
    justify-content: center;
    align-items: center;
    float:left;
}

.statusTitle{
    padding-top: 20px;
    justify-content: center;
    align-items: center;
}

.statusGraphicContainer{
    //display: flex;
    justify-content: center;
    align-items: center;
}

.list{
    flex-wrap: nowrap;
    justify-content: space-between;

    display: flex;
    gap: 2em;
    align-items: center;
}

.lists{

}

.captionStatus{
    font-size: 14px;
}

.captionStatusDetails{
    font-size: 14px;
    margin: 2px;
    text-transform: capitalize;
}

.captionUpdate{
    font-size: 12px;
    color: grey;
}

.track{
    margin: 4em 0 8em;
}

#progress{
    list-style: none;
    display: flex;
    //flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    position: relative;
    text-align: center;
}

#progress li{
    width: 20%;
    position: relative;
}

#progress li::before{
    content: '\2713';
    position: absolute;
    display: flex;
    justify-content: center;
    width: 50px;
    height: 50px;
    font-size: 1.8rem;
    align-content: center;
    background: #026BFB;
    color: white;
    border-radius: 50%;
    z-index: 11111;
}

#progress li:last-child::before{
    content: '\2713';
    font-weight: bold;
    background: #7db3ff
}

#progress::before{
    content: '';
    position: absolute;
    top: 20px;
    width: 55%;
    height: 8px;
    background: #026BFB; 
    z-index: 111;
}

#progress::after{
    content: '';
    position: absolute;
    top: 20px;
    width: 80%;
    height: 8px;
    background: #7db3ff;
}

