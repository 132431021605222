.SOVContainer{
    display: flex;
    flex-wrap: wrap;

    margin-left: 65px;
    min-height: 80vh;

    flex-direction: column;
    
    align-items: center;
    align-content: center;
    background-color: #FAFAFA;

    @media screen and (max-width: 500px){
        margin-left: 0px;
    }
}



.SOVFlexItem{
    width: 90%;

    //min-height: 80vh;
    //border: .1px solid rgb(201, 201, 201);
    //background-color: grey;
    //margin: 50px;
    //margin-left: 65px;
    margin-top: 50px;
    
}

.SOVFlexItem-1{
    //min-height: 80px;
    //margin-top: 50px;

    //min-height: 50vh;

    //border: 1px solid rgb(0, 0, 0);
    //background-color: grey;
    //margin: 50px;
    //display: flex;
    //gap: 20px;
}

.SOVFlexItem-2{
    background-color: white;
    min-height: 80px;
    //margin-top: 50px;
    margin-bottom: 50px;

    min-height: 50vh;

    border: 0.1px solid rgb(223, 223, 223);
    border-radius: 5px;
    //background-color: grey;
    //margin: 50px;
    //display: flex;
    gap: 50px;
}

.timelineOveride{
 flex: none;
 flex-wrap: wrap-reverse;
}


.single {
    display: flex;
    width: 100%;
    .singleContainer {
      flex: 6;
  
      .top {
        padding: 20px;
        display: flex;
        gap: 50px;
  
        .left {
          flex: 2;
          -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
          box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
          padding: 20px;
          position: relative;
          background-color: white;
        }
  
        .right {
          flex: 1;
        }
      }

    }
  }

