.step1-title{
    background: none;
	color: inherit;
	border: none;
    //display: flex;
    //align-items: center;
    text-decoration: none;
    height: 100%;
    font-size: 20px;
    cursor: pointer;
    box-sizing: border-box;
    //background-color: green;
    //float: left;

    &.active{
        border-bottom: 3px solid #026BFB
    }

    &:hover{
        color: black;
        //background-color: green;
    }
}

.modalInstruction{
    //margin-top: 50px;
    color: #717171;
    font-size: 20px;
}
.modalInstructionUnderline{
    margin-top: -15px;
    border-bottom: 5px solid #717171;
    width: 45px;
}

.orderEntry {
    //background-color: green;
    display: flex;
    //margin-left: 50px;
    margin-top: 50px;
    width: 90%;
    //float: left;
    height: 50%;
    //border-bottom: 1px solid #D9D9D9;
}

.detailAddNew{
    margin-left: 25px;
    margin-top: 10px;
    }
    
.detailAddNewIcon{
    display: flex;
    margin-left: 5px;
    margin-top: 20px;
    }

.detailTitle{
    margin-right: 25px;
    color: #717171;
    
}

.detailAddNewText{
    padding-left: 25px;
    width: 130px;
}