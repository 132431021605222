.detailsContainerSOV{
    flex: 1;
    padding: 10px;
    border-radius: 10px;
}

.editOrder{
    border-radius: 50px;
    background: #D9D9D9;
    white-space: nowrap;
    padding: 10px 22px;
    color: #060301;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    margin-top: -35px;
    float: right;
    max-width: 200px;
    margin-right: 30px;
    margin-left: 25px;

    position: relative;
    //display: flex;
    align-items: center;
    justify-content: center;


    &:hover {
        transition: all 0.2s ease-in-out;
        background: #026BFB;
        color: #010606;
        
    }
}

.step1-titleSOV{
    background: none;
	color: inherit;
	border: none;
    //display: flex;
    //align-items: center;
    text-decoration: none;
    height: 100%;
    font-size: 20px;
    cursor: pointer;
    box-sizing: border-box;
    //background-color: green;
    //float: left;

    &.active{
        border-bottom: 3px solid #026BFB
    }

    &:hover{
        color: black;
        //background-color: green;
    }
}

.modalInstructionSOV{
    //margin-top: 50px;
    color: #717171;
    font-size: 20px;
}
.modalInstructionUnderlineSOV{
    margin-top: -15px;
    border-bottom: 5px solid #717171;
    width: 45px;
}

.detailHeaderSOV{
    padding-bottom: 20px;
    border-bottom: .1px solid rgb(201, 201, 201);
    margin-bottom: 20px;

}

.orderCellSOVKey{
    margin-top: 0px;
    margin-bottom: 0px;
    color: #686868;
    font-size: 14px;
}

.orderCellSOVValue{
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
    //margin-right: 10px;
}


.single {
    display: flex;
    width: 100%;
    .singleContainer {
      flex: 6;
  
      .top {
        padding: 20px;
        display: flex;
        gap: 20px;
  
        .left {
          flex: 2;
          -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
          box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
          padding: 20px;
          position: relative;
  
  
          .item {
            display: flex;
            gap: 20px;
            
  
            .itemImg {
              width: 100px;
              height: 100px;
              border-radius: 50%;
              object-fit: cover;
            }
  
            .detailsSOV {
              padding: 20px;
              display: flex;
              flex-wrap: wrap;
  
                .itemKey {
                  font-weight: bold;
                  color: rgb(163, 163, 163);
                  margin-right: 5px;
                }
  
                .itemValue {
                  font-weight: 300;
                  text-transform: capitalize;
                }
              }
            }
          
        }
  
        .right {
          flex: 1;
        }
      }
  
      .bottom {
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 20px;
        margin: 10px 20px;
      }
  
      .title {
        font-size: 16px;
        color: lightgray;
        margin-bottom: 20px;
      }
    }
  }

  .itemTitleSOV {
    //margin-bottom: 10px;
    color: #555;
  }

  .detailItemSOV {
    //padding-bottom: 100px;
    font-size: 14px;
  }