.trackingAction{
    padding-left: 50px;
    //margin: 20px;
}

.originText{

}

.destinationText{
    color: #ff8635
}

.trackTextBox{
    float: right;
    padding-right: 350px;
}


.trackOrder{
    border-radius: 50px;
    background: #D9D9D9;
    white-space: nowrap;
    padding: 10px 22px;
    color: #060301;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    //margin-top: -35px;
    float: right;
    max-width: 200px;
    margin-right: 70px;
    //margin-left: 25px;

    position: relative;
    //display: flex;
    align-items: center;
    justify-content: center;


    &:hover {
        transition: all 0.2s ease-in-out;
        background: #026BFB;
        color: #010606;
        
    }
}