.root{
    overflow: visible;
    //background-color: green;
    position: relative;
    margin-top: 50px;
    width: 100%;
    height: 50%;
    //text-transform: capitalize;
}

.tab{
    overflow: visible;
    width:200px;
    border-bottom: 5px solid #D9D9D9;
    padding-bottom: 10px;
    margin-right: 20px;
    color: rgb(204, 204, 204);
    //background-color: green;
}

.indicator{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transition:cubic-bezier(0.29, 1.42, 0.79, 1);
}

.orderStages {
    //background-color: green;
    margin-top: 50px;
    width: 100%;
    height: 50%;
}

.orderStageMenu{
    display: flex;
    flex-wrap: wrap;
    //align-items: center;
    list-style:  none;
    //text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 35px;
}

.orderStageItem{
    width:200px;
    border-bottom: 5px solid #D9D9D9;
    padding-bottom: 10px;
    margin-right: 20px;
    color: rgb(204, 204, 204);

}

.orderStageItemComplete{
    width:200px;
    border-bottom: 5px solid #026BFB;
    padding-bottom: 10px;
    margin-right: 20px;

    &.active{
        font-size: 8px;
    }
    
}

.activeStep{
    width:200px;
    border-bottom: 5px solid #026BFB;
    padding-bottom: 10px;
    margin-right: 20px;

    &.active{
        font-size: 8px;
    }
    
}

