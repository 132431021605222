.stepTwo{
    //background-color: green;
    //color: blue;
    display:flex;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    
    align-items: center;
    align-content: center;
    background-color: #FAFAFA;

    @media screen and (max-width: 500px){
        margin-left: 0px;
    }
}

.column{
    display: flex;
    //float: left;
}

.product{
    width: 50%;
    background-color: rgb(55, 0, 255);
}

.contact{
    //padding-left: 25px;
    width: 50%;
       
    float: right;
    background-color: red;
}

.columnS2 {
    float: left;
    width: 50%;
  }
  
  /* Clear floats after the columns */
  .rowS2:after {
    content: "";
    display: table;
    clear: both;
  }

  .rightS2{
    //margin-left: 10px;
    background-color: red;
  }

  .leftS2{
    //margin-left: 10px;
    background-color: rgb(17, 211, 37);
    padding: 3px;
  }

  .stepTwoTextField{
    padding: 10px;
    margin-bottom: 10px;
  }