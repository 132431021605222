*{
    //padding: 0;
    //margin: 0;
    //box-sizing: border-box; 
    // Border box used to account border to specified height and width
}

.navbar {
    height: 75px;
    background-color : rgb(0, 0, 0);
}


.logo {
    height: 75px;
    width: 75px;
    padding: 12.5px;
}

.logoName {
    font-size: 28px;
    margin-top: auto;
    margin-bottom: auto;
}

.navMenu {
    margin-left: 18px;

    @media screen and (max-width: 768px){
        display: none !important;
    }
}

.navIcons {
    @media screen and (max-width: 768px){
        display: none !important;
    }
}

.NavBtnLink {
    
    border-radius: 50px;
    background: #026BFB;
    white-space: nowrap;
    padding: 10px 22px;
    color: #010606;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    margin: 5px;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #010606;
    }

    @media screen and (max-width: 768px){
        //margin: 0px;
        display: none !important;
    }
}





.navbarRight {
    //background-color: rgba(255, 0, 0, 0.5);
    margin-right: 20px;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
}
