.dashboardContainer{
    display: flex;
    flex-wrap: wrap;
    margin-left: 65px;
    min-height: 80vh;

    flex-direction: column;
    
    align-items: center;
    align-content: center;
    background-color: #FAFAFA;

    @media screen and (max-width: 500px){
        margin-left: 0px;
    }
}

.dashboardFlexibleItem{
    width: 90%;

    //height: 100px;
    //border: .1px solid rgb(201, 201, 201);
    //background-color: grey;
    //margin: 50px;
}

.dashboardFlexibleItem-1{
    min-height: 40px;
    margin-top: 50px;
    margin-bottom: 10px;

    //height: 100px;
    //border: 1px solid rgb(0, 0, 0);
    //background-color: grey;
    //margin: 50px;
}

.dashboardFlexibleItem-2{
    min-height: 100px;
    //margin-top: 50px;

    //height: 100px;
    //border: 1px solid rgb(0, 0, 0);
    //background-color: grey;
    //margin: 50px;

    display: flex;
    //padding: 20px;
    gap: 20px;
    //padding-bottom: 5px;
}

.dashboardFlexibleItem-3{
    min-height: 100px;
    //margin-top: 50px;
    margin-bottom: 50px;

    // height: 100px;
    // border: 1px solid rgb(0, 0, 0);
    // background-color: grey;
    margin-top: 50px;

    display: flex;
    //padding: 20px;
    gap: 20px;
    //padding-bottom: 5px;
}

.dashboardFlexibleItem-4{
    min-height: 100px;
    margin-bottom: 50px;
    //margin-top: 50px;

    // height: 100px;
    // border: 1px solid rgb(0, 0, 0);
    // background-color: grey;
    //margin: 50px;

    display: flex;
    //padding: 20px;
    gap: 20px;
    //padding-bottom: 5px;
}