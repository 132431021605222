.rootSOV{
    overflow: visible;
    //background-color: green;
    position: relative;
    margin-top: 50px;
    width: 100%;
    height: 50%;
    //text-transform: capitalize;
}

.tabSOV{
    overflow: visible;
    width:200px;
    border-bottom: 5px solid #D9D9D9;
    padding-bottom: 10px;
    margin-right: 20px;
    color: rgb(204, 204, 204);
    //background-color: green;
}

.indicatorSOV{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transition:cubic-bezier(0.29, 1.42, 0.79, 1);
}

.orderStagesSOV {
    //background-color: green;
    //margin-top: 25px;
    width: 100%;
    height: 20%;
    border-bottom: .1px solid rgb(201, 201, 201);
    padding-bottom: 20px;
}

.orderStageMenuSOV{
    display: flex;
    flex-wrap: wrap;
    //align-items: center;
    list-style:  none;
    //text-align: center;
    margin-top: 25px;
    margin-bottom: 0px;
    margin-left: 35px;
}

.orderStageItemSOV{
    width:150px;
    border-bottom: 5px solid #D9D9D9;
    padding-bottom: 10px;
    margin-right: 20px;
    color: rgb(204, 204, 204);
    text-align: center;

}

.orderStageItemCompleteSOV{
    width:100px;
    border-bottom: 5px solid #026BFB;
    padding-bottom: 10px;
    margin-right: 20px;
    

    &.active{
        font-size: 8px;
    }
    
}

.activeStepSOV{
    width:150px;
    border-bottom: 5px solid #026BFB;
    padding-bottom: 10px;
    margin-right: 20px;
    text-align: center;
    

    &.active{
        font-size: 8px;
    }
    
}
