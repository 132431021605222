* {
    padding: 0;
    //margin: 0;
    //box-sizing: border-box; 
    // Border box used to account border to specified height and width
}


.container {
    //background-color: #FAFAFA;
    //display: flex;
    //background-color: red;
    height: 100vh;
    width: 100vw;
    margin-top: -50px;
    //display: flex;
    //position: absolute;
    //margin-top: 0;

}

body {
    background-color: #FAFAFA;

}

.paper {
    display: flex;
    margin-left: 115px;
    margin-top: 50px;
    margin-right: 50px;
    font-size: 36px;
    font-family: Arial, Helvetica, sans-serif;

}

.split {
    height: 100%;
    width: 50%;
}

.left {
    padding: 25px;
    left: 0;
    //background-color: yellow;
}

.right {
    padding-top: 25px;
    right: 0;
    //background-color: red;
    
}

.newOrder {

    border-radius: 50px;
    background: #D9D9D9;
    white-space: nowrap;
    padding: 10px 22px;
    color: #010606;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    margin: 5px;
    float: right;
    max-width: 200px;
    margin-right: 30px;
    margin-left: 25px;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;


    &:hover {
        transition: all 0.2s ease-in-out;
        background: #026BFB;
        color: #010606;
        
    }
}

.newOrderBadge {
    position:absolute;
    top: -15px;
    right: -18px;
    color: #026BFB;
    padding: 2px;
}

.searchBar {
    //display: inline-block;
    //background-color: red;
    max-width: 300px;
    max-height: 40px;
    padding-top: 5px;
    
    float: right;
}

.searchBar .MuiOutlinedInput-root {
    border-radius: 20px;
  }


.orderCategoryBar {
    display: flex;
    margin-left: 115px;
    margin-top: 0px;
    margin-right: 50px;
    //font-size: 36px;
    font-family: Arial, Helvetica, sans-serif;

    border-bottom: 1px solid #EEEEEE

}


.orderCategoryMenu {
    display: flex;
    align-items: center;
    list-style:  none;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;


    padding-left: 5px
    //margin-left: 10px;
    //background-color: red;
}

.orderCategoryItem {
    height: 50px;
    //background-color: yellow;
    width: 200px;
}

.orderCategoryButton {
    background: none;
	color: inherit;
	border: none;
	//padding: 0;
	//font: inherit;

    color: rgb(204, 204, 204);
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    font-size: 20px;
    cursor: pointer;

    box-sizing: border-box;

    
    &.active{
        border-bottom: 3px solid #026BFB
    }

    &:hover{
        //color: #026BFB;
        //font-weight: bold;
        //transition: 0.2s ease-in-out;
        border-bottom: 3px solid #515151;
        color: black
        //text-decoration: overline;

    }
}

.orderTable {
    display: flex;
    margin-left: 115px;
    margin-top: 0px;
    margin-right: 50px;
    //font-size: 36px;
    //font-family: Arial, Helvetica, sans-serif;

    border-bottom: 1px solid #EEEEEE
}

.tableHead .MuiTableHead-root{
    font-weight: 550;
    font-size: 16px;
    //color: #e01010;
}

.emptyOrders{
    //display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    text-align: center;
}

.firstOrder {
    border-radius: 50px;
    background: #D9D9D9;
    white-space: nowrap;
    padding: 10px 22px;
    color: #010606;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    margin: 5px;
    //float: right;
    max-width: 200px;
    margin-right: 30px;
    margin-left: 25px;
    margin-bottom: 100px;



    &:hover {
        transition: all 0.2s ease-in-out;
        background: #026BFB;
        color: #010606;
        
    }
}

.orderRow {
    background-color: #F7F7F7;
}

.orderCell{
    //background-color: yellow;
    margin-top: 0px;
    margin-bottom: 0px;
}

.rectangle{
    width: 200px;
    background-color: #D9D9D9;
    height: 5px;
    display: inline-block;
}



.circle{
        width: 13px;
        height: 13px;
        background: white;
        border-radius: 50%;
        float: right;
        vertical-align:middle;
        border: 1.5px solid #000000;

        margin-top: -3.5px;
        margin-right: 0px;
}

.statusView{
    //background-color: red;
}
.statusBarText{
    font-size: 14px;
    float: left;
    //margin-left: 10px; 
    margin-top: -3px;
}

.statusBarText2{
    float: right;

    font-size: 14px;
    //margin-left: 10px;
    //margin-right: 10px;
    margin-top: -3px;
}

.statusBarText3{
    float: left;

    font-size: 14px;
    margin-left: 10px;
    margin-bottom: -100px;
    //display: flex;
}