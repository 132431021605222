.widget {
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding: 10px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.1);
    border-radius: 10px;
    height: 100px;
    background-color: rgb(255, 255, 255);

    flex-direction: column;
    min-height: 120px;
  }

  .addIcon{
    color: #026BFB;
    padding-top: 0px;
    float: right;

    &:hover {
      transition: all 0.2s ease-in-out;
      color: #383838; 
  }
  }

  .widgetTitle{
    float:left;
  }

  .widgetCount{
    padding-left: 10px;
    margin: 0px;
    
    font-size: 26px;
  }

  .widgetLink{
    font-size: 14px;
    margin: 0px;
    padding-left: 10px;
    color: #026BFB;

    &:hover {
      transition: all 0.2s ease-in-out;
      color: #383838; 
  }
  }