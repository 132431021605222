.inquiry{
    flex: 2;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.1);
    //padding: 10px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        //color: gray;
    
        .title {
            //font-weight: bold;
            font-size: 20px;
            padding-left: 15px;
            padding-top: 10px;
            //color: rgb(160, 160, 160);
        }
      }

      form {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        justify-content: space-around;

        .formInput {
          width: 95%;
          padding-top: 20px;
          padding-bottom: 15px;

          label {
            display: flex;
            align-items: center;
            gap: 10px;

            .icon {
              cursor: pointer;
            }
          }

          input {
            width: 100%;
            padding: 5px;
            border: none;
            border-bottom: 0.5px solid gray;
          }
        }

        button {
            
          width: 150px;
          
          border: none;
          background-color: teal;
          color: white;
          font-weight: bold;
          cursor: pointer;
          margin-top: 10px;
          margin-right: 10px;

          border-radius: 50px;
            background:  rgba(235, 234, 234, 1);
            white-space: nowrap;
            padding: 10px 22px;
            color: #010606;
            font-size: 10px;
            outline: none;
            border: none;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            text-decoration: none;

            &:hover {
                transition: all 0.2s ease-in-out;
                background: #026BFB;
                color: #010606;
            }
        }

        .button-two {
          width: 150px;
          
          border: none;
          background-color: teal;
          color: white;
          font-weight: bold;
          cursor: pointer;
          margin-top: 10px;
          margin-right: 10px;

          border-radius: 50px;
            background:  #026BFB;
            white-space: nowrap;
            padding: 10px 22px;
            color: #010606;
            font-size: 10px;
            outline: none;
            border: none;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            text-decoration: none;

            &:hover {
                transition: all 0.2s ease-in-out;
                background: rgba(235, 234, 234, 1);
                color: #010606;
            }

        }
      }
}

